<template>
<div class="row">

    <div class="col-6 col-md-6 flex justify-center content-center d-none d-md-block d-sm-none">
      <div class="slide-content bg-1"> </div>
    </div>
    <div class="col-12 col-md-6 flex justify-center content-center formdiv align-middle" >
    
        <div class="inner-block align-middle">
			
               <h1 class="mr-auto"><strong>Bitcom</strong> <small>IOT</small></h1>
       <h4 class="mr-auto">Let's get started</h4>
        <form @submit.prevent="submit">
            <div class="form-group">
                
                <input type="email" v-model="form.email" class="form-control form-control-lg" placeholder="Enter Email"/>
            </div>

            <div class="form-group">
                
                <input type="password" v-model="form.password" class="form-control form-control-lg" placeholder="Enter Password"/>
            </div>
            <p v-if="showError" id="error">Username or Password is incorrect</p>
            <button type="submit" class="btn btn-dark btn-lg btn-block">Sign In</button>

     



        </form>
        </div>
		<p class="copy">Copyright @2021 - bitcom.ro</p>
    </div>
</div>
</template>
<style scoped>

.slide-content.bg-1 {
    background: url(../assets/digital.jpg) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100vh;
}
.inner-block{
      margin-top: calc(30vh - 50px);
          min-width: 360px;
}
form{
padding:20px 0px;
}

.row {
    --bs-gutter-x: 0rem;
}	
.formdiv {
    background:#fff;
}
.btn-block{
    margin:15px 0px;
}
.form-group {
    margin:15px 0px;  
}
img.logo {
width: 84px;
}
p.copy{
    text-align: right;
    position: absolute;
    bottom: 0px;
    right: 20px;
    font-size: 12px;	
}
.small, small {
    font-size: .575em;
}
p#error{color: red;}
</style>
<script>
  import { mapActions } from "vuex";
    export default {
    name: "Login",
    components: {},
    data() {
        return {
        form: {
            email: "",
            password: "",
        },
        showError: false
        };
    },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("email", this.form.email);
      User.append("password", this.form.password);
      try {
          await this.LogIn(User);
          this.$router.push("/");
          this.showError = false
      } catch (error) {
        this.showError = true
      }
    },
  },
};
</script>
